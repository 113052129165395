import React from "react";
import Timeline from "../components/Timeline";
import Landing from "../components/Landing";
import AboutMe from "../components/AboutMe";
import Services from "../components/Services";
import Footer from "../components/Footer";

const HomePage = () => {

    return (
        <>
            <Landing/>
            <AboutMe/>
            <Services/>
            <Timeline/>
            <Footer/>
        </>
    )
}

export default HomePage;