import 'bootstrap/dist/css/bootstrap.css';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

const Navigation = () => {
    return (
        <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
            <Container>
                <LinkContainer to="/" style={{color: "black"}}>
                    <Navbar.Brand>Dave-IT</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <LinkContainer to="/about">
                            <Nav.Link style={{color: "black"}}>About</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;