import styles from "./Footer.module.css"

const Footer = () => {
    return (
        <footer>
            <div className={styles.footer}>
                <small>© 2024 Dave IT</small>
            </div>
        </footer>
    )
}

export default Footer;