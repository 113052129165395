import React, {useEffect, useState} from "react";
import Markdown from "react-markdown";

export interface MarkdownContentProps {
    filename: string;
}

const MarkdownContent: React.FC<MarkdownContentProps> = (props) => {
    // console.log(props)
    const [markdown, setMarkdown] = useState('')

    useEffect(() => {
        fetch(props.filename)
            .then(res => {
                return res.text()
            })
            .then(text => {
                setMarkdown(text)
            })
            .catch(r => console.log(r))
    }, [props]);

    return (
        <>
            <Markdown children={markdown}/>
        </>
    )

}

export default MarkdownContent;