import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import React from "react";
import {GrFlag, GrUserManager} from "react-icons/gr";
import {IoBusinessOutline} from "react-icons/io5";
import {MdOutlineSchool} from "react-icons/md";
import {FaArrowTrendUp, FaLaptop} from "react-icons/fa6";
import styles from './Timeline.module.css';

const Timeline = () => (
    <>
        <div className={styles.commonmodule}>
            <VerticalTimeline lineColor={"gray"}>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2024 - now"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<GrFlag/>}
                >
                    <h3 className="vertical-timeline-element-title">Self-Employed</h3>
                    <p>
                        Start as freelance developer, focusing on Java
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2022 - 2024"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<GrUserManager/>}
                >
                    <h3 className="vertical-timeline-element-title">ING</h3>
                    <h4 className="vertical-timeline-element-subtitle">Chapter Lead</h4>
                    <p>
                        Managed a group of engineers while still working 50% as an engineer
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2019 - 2022"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<FaLaptop/>}
                >
                    <h3 className="vertical-timeline-element-title">ING</h3>
                    <h4 className="vertical-timeline-element-subtitle">Engineer</h4>
                    <p>
                        Worked as a DevOps engineer designing and developing self-service solutions for customers with
                        financial difficulties
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2017 - 2019"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<FaArrowTrendUp/>}
                >
                    <h3 className="vertical-timeline-element-title">ING</h3>
                    <h4 className="vertical-timeline-element-subtitle">Engineer</h4>
                    <p>
                        Worked as a DevOps engineer on implementing real-time processing solutions loading high volumes
                        of data
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2014 - 2017"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<IoBusinessOutline/>}
                >
                    <h3 className="vertical-timeline-element-title">ATOS</h3>
                    <h4 className="vertical-timeline-element-subtitle">Application Developer</h4>
                    <p>
                        Worked as a DevOps engineer on Java applications for masking production data in order to make it
                        usable for test purposes, in compliance with GDPR standards
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2006 - 2010"
                    iconStyle={{background: '#568ce3', color: '#fff'}}
                    icon={<MdOutlineSchool/>}
                >
                    <h3 className="vertical-timeline-element-title">Bachelor of Engineering</h3>
                    <p>
                        Graduated Human-Computer Interaction with focus on usability and user experience
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    </>
);

export default Timeline;

