import MarkdownContent from "../components/MarkdownContent";
import React from "react";
import markdown from '../assets/markdown/AboutMe.md'

const AboutMePage = () => {
    return (
        <div style={{
            maxWidth: "70ch",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "30px auto",
            padding: "20px"
        }}>
            <MarkdownContent filename={markdown}/>
        </div>
    )
}
export default AboutMePage;

