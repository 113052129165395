import React from 'react';
import styles from './Landing.module.css';
import bg from '../assets/images/carousel-desktop-borrowed.jpg';

const Landing = () => {
    return (
        <>
            <div>
                <img src={bg} alt={"background"} className={styles.image}/>
            </div>
            <div className={styles.text}>
                <h1>David Jansen</h1>
                <h3>Dutch software engineer for Java</h3>
            </div>
        </>
    )
}

export default Landing;