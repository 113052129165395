import React from "react";
import styles from "./AboutMe.module.css"
import SectionHeader from "./SectionHeader";
import face from "../assets/images/my_face_120px.png"
import {IconContext} from "react-icons";
import {FaLinkedin} from "react-icons/fa6";

const iconsstyles = {
    color: "#568ce3",
    size: "3em",
}

const AboutMe = () => {
    return (
        <>
            <div className={styles.commonmodule} id={"about"}>
                <SectionHeader title={"About Me"} subtitle={"Get to know me a little!"}/>
                <div className={styles.imagecropper}>
                    <img src={face} alt={"Me"} className={styles.profilepic}/>
                </div>
                <ul>
                    <li className={styles.serviceitem}>
                        <a href="https://www.linkedin.com/in/dpmjansen/">
                            <IconContext.Provider value={iconsstyles}>
                                <FaLinkedin/>
                            </IconContext.Provider>
                        </a>
                    </li>
                </ul>
                <div className={styles.text}>
                    I am a self-employed software engineer dedicated to Java for client-server architectures.
                    After obtaining my Bachelor's Degree in engineering, I gained experience from working in an
                    enterprise environment,
                    where I used Java intensively during both my professional life and spare time.

                    I am happy to welcome you on my website :-)
                </div>
            </div>
        </>
    )
}

export default AboutMe;
