import React from "react";
import styles from "./SectionHeader.module.css"

export interface SectionHeaderProps {
    title: string,
    subtitle: string,
}

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
    return(
        <div className={styles.sectionheader}>
            <h2>{props.title}</h2>
            <h5>{props.subtitle}</h5>
        </div>
    );
}

export default SectionHeader