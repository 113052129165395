import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import "./assets/App.css"

import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import AboutMePage from "./pages/AboutMePage";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Navigation/>
                <Routes>
                    <Route index element={<HomePage/>}/>
                    <Route path='/about' element={<AboutMePage/>}/>
                    {/*<Route path='/hire' element={<HirePage/>}/>*/}
                    <Route path='*' element={<Navigate to="/" replace/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;