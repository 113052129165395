import SectionHeader from "./SectionHeader";
import {MdTroubleshoot} from "react-icons/md";
import styles from "./Services.module.css";
import {IconContext} from "react-icons";
import {FaCakeCandles, FaEye, FaRegKeyboard} from "react-icons/fa6";

const iconsstyles = {
    color: "#568ce3",
    size: "3em",
}

const Services = () => {
    return (
        <div className={styles.commonmodule}>
            <SectionHeader title={"What I offer"} subtitle={"Why you might want to hire me"}/>
            <ul className={styles.serviceitems}>
                <li className={styles.serviceitem}>
                    <IconContext.Provider value={iconsstyles}>
                        <FaCakeCandles/>
                    </IconContext.Provider>
                    <h4>MVP</h4>
                    <p>Realising your idea as a minimal viable product (MVP) and beyond</p>
                </li>
                <li className={styles.serviceitem}>
                    <IconContext.Provider value={iconsstyles}>
                        <MdTroubleshoot/>
                    </IconContext.Provider>
                    <h4>Code reviews</h4>
                    <p>Conduct code reviews for your application together with your team</p>
                </li>
                <li className={styles.serviceitem}>
                    <IconContext.Provider value={iconsstyles}>
                        <FaEye/>
                    </IconContext.Provider>
                    <h4>Complex problems</h4>
                    <p>Solve complex problems in Java code which no one dares to fix</p>
                </li>
                <li className={styles.serviceitem}>
                    <IconContext.Provider value={iconsstyles}>
                        <FaRegKeyboard/>
                    </IconContext.Provider>
                    <h4>Writing code</h4>
                    <p>Work along with your team on large scale applications</p>
                </li>
            </ul>
            {/*<Row xs={1} md={"auto"} className="g-4">*/}
            {/*    <Col>*/}
            {/*        <Card border="0" className={styles.offercard}>*/}
            {/*            <Card.Body>*/}
            {/*                <IconContext.Provider value={iconsstyles}>*/}
            {/*                    <FaCakeCandles/>*/}
            {/*                </IconContext.Provider>*/}
            {/*                <Card.Title>MVP</Card.Title>*/}
            {/*                <Card.Text>Realising your idea as a minimal viable product (MVP) and beyond</Card.Text>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <Card border="0" className={styles.offercard}>*/}
            {/*            <Card.Body>*/}
            {/*                <IconContext.Provider value={iconsstyles}>*/}
            {/*                    <FaEye/>*/}
            {/*                </IconContext.Provider>*/}
            {/*                <Card.Title>Code reviews</Card.Title>*/}
            {/*                <Card.Text>Conduct code reviews for your application together with your team</Card.Text>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <Card border="0" className={styles.offercard}>*/}
            {/*            <Card.Body>*/}
            {/*                <IconContext.Provider value={iconsstyles}>*/}
            {/*                    <MdTroubleshoot/>*/}
            {/*                </IconContext.Provider>*/}
            {/*                <Card.Title>Complex problems</Card.Title>*/}
            {/*                <Card.Text>Solve complex problems in Java code which no one dares to fix</Card.Text>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <Card border="0" className={styles.offercard}>*/}
            {/*            <Card.Body>*/}
            {/*                <IconContext.Provider value={iconsstyles}>*/}
            {/*                    <FaRegKeyboard/>*/}
            {/*                </IconContext.Provider>*/}
            {/*                <Card.Title>Writing code</Card.Title>*/}
            {/*                <Card.Text>Work along with your team on large scale applications</Card.Text>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    )
}

export default Services;